import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DemoForm from "../components/demoForm"
import Iframe from "react-iframe"

export default function() {
  return (
    <Layout>
      <SEO title={"Contact Us"}/>
      <div className={"row"}>
        <div className={"col-lg"}>
          <div className={"row mb-3 d-none d-md-flex"}>
            <div className={"col"}>
              <Iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.8443195003492!2d-117.84093758384283!3d33.71297708069948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdeac5f41f24f%3A0xfde1beb7cd9485b2!2sPowersports%20Support!5e0!3m2!1sen!2sus!4v1570211724713!5m2!1sen!2sus"
                width="100%" height="550"/>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col"}>
              <address>
                <strong>Powersports Support, LLC.</strong><br/>
                <a href={"mailto:info@powersportsupport.com"}>info@powersportsupport.com</a><br/>
                <a href="tel:+18006334374" className={"text-secondary"}>+1 (800) 633-4374</a><br/>
                1381 Warner Ave.<br/>
                Tustin, CA 92780
              </address>
            </div>
          </div>
        </div>
        <div className={"col-lg"}>
          <DemoForm/>
        </div>
      </div>
    </Layout>
  )
}
